<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-4 md:c-col-8 lg:c-col-6">
        <div class="ui-relative c-bg-white ui-rounded-md">
          <!-- Content -->
          <div class="c-bg-white ui-p-8">
            <!-- Título -->
            <div class="c-text-h3 c-text-gray ui-mb-2">
              Criar novo grupo
            </div>

            <!-- Subtítulo -->
            <div class="c-text-b2 c-text-gray ui-mb-6">
              Digite o nome do novo grupo a ser criado
            </div>

            <!-- Formulário -->
            <p-form ref="form">
              <!-- Nome -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <p-input
                    data-cy="name-field"
                    label="Nome"
                    class="ui-mb-4"
                    v-model.trim="groupName"
                    :validator="validateName"
                  />
                </div>
              </div>

              <div class="c-row c-horizontal-end">
                <div class="c-col-1/2">
                  <c-button
                    full
                    outline
                    data-cy="cancelarButton"
                    @click="$modal.hide()"
                    :disabled="isFormSending"
                  >
                    Cancelar
                  </c-button>
                </div>

                <div class="c-col-1/2">
                  <c-button
                    full
                    type="submit"
                    data-cy="criarGrupoButton"
                    @click="createGroup"
                    :disabled="!isFormValid()"
                    :loading="isFormSending"
                  >
                    Criar
                  </c-button>
                </div>
              </div>
            </p-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PInput from '../../components/p-input';
import PForm from '../../components/p-form';
import { CButton } from '@estrategiahq/coruja-web-ui';

export default {
  components: {
    PInput,
    PForm,
    CButton,
  },
  data () {
    return {
      isFormSending: false,
      groupName: '',
    };
  },
  methods: {
    validateName (name) {
      return name === '' ? 'Nome é obrigatório' : false;
    },
    isFormValid () {
      return this.$refs.form?.valid();
    },
    async createGroup () {
      if (this.isFormValid()) {
        this.isFormSending = true;
        const createdGroup = await this.$s.group.create({ name: this.groupName }).finally(() => this.isFormSending = false);
        this.$toast.show({ type: 'success', text: 'Grupo criado com sucesso' });
        this.$modal.hide();
        this.$router.push({ name: 'groups-detail', params: { id: createdGroup.id } });
      }
    },
  },
};
</script>
