<template>
  <div class="c-row">
    <div class="ui-m-4 ui-mt-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Grupos
          </span>
        </div>
      </div>

      <!-- Subtítulo e Botão Adicionar grupo -->
      <div class="c-row c-vertical-center ui-justify-between ui-mb-4">
        <!-- Subtítulo -->
        <div class="c-col md:c-col-8">
          <span class="c-text-subtitle c-text-gray-300">
            Gerencie regras e permissões de acesso dos usuários da Estratégia
          </span>
        </div>

        <div class="c-col c-d-flex c-horizontal-end">
          <!-- Botão Adicionar grupo -->
          <c-button
            v-if="showCreateGroupButton"
            data-cy="createGroupButton"
            @click="create"
            class="ui-mr-2 ui-whitespace-no-wrap"
          >
            Adicionar grupo
            <icon
              name="add"
              outline
              class="c-icon--right"
            />
          </c-button>

          <!-- Botão Listagem de Usuáios -->
          <c-button
            outline
            data-cy="voltarButton"
            @click="$router.push({ name: 'users' })"
            class="ui-w-48"
          >
            Voltar
          </c-button>
        </div>
      </div>

      <!-- Tabela -->
      <c-table
        :columns="columns"
        :data="groups"
      >
        <template v-slot:column-name="{ item: group }">
          <div class="c-d-flex ui-items-center ui-justify-between ui-w-full">
            <span>{{ group.name }}</span>
            <div class="c-d-flex">
              <clickable-icon
                name="user"
                hoverable
                class="ui-p-3 ui-mr-2"
                :data-cy="`userGroup-${ group.name }`"
                @click="changeUsers(group.id)"
              />
              <clickable-icon
                name="edit"
                hoverable
                class="ui-p-3 ui-mr-2"
                :data-cy="`editGroup-${ group.name }`"
                @click="edit(group.id)"
              />
              <clickable-icon
                name="trash"
                color="var(--c-color-orange)"
                class="ui-p-3 ui-mr-2"
                :data-cy="`trashGroup-${ group.name }`"
                @click="remove(group)"
              />
            </div>
          </div>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import CreateGroupModal from './CreateGroupModal';
import CTable from './../../components/c-table/CTable';
import ClickableIcon from '../../components/clickable-icon';

const isSuperAdmin = item => item.permissions.some(p => p.id === '*');

export default {
  components: {
    ClickableIcon,
    CTable,
    Icon,
    CButton,
  },
  props: {
    loggedUser: { type: Object, required: true },
  },
  data () {
    return {
      columns: [{
        label: 'Nome do Grupo',
        attribute: 'name',
      }],
      groups: [],
    };
  },
  async mounted () {
    this.groups = await this.$s.group.getAll();
  },
  computed: {
    showCreateGroupButton () {
      return this.loggedUser?.hasPermission('accounts.groups.create');
    },
  },
  methods: {
    create () {
      this.$modal.show({ component: CreateGroupModal, overlayOpacity: 0.7 });
    },
    changeUsers (id) {
      this.$router.push({ name: 'groups-users', params: { id } });
    },
    edit (id) {
      this.$router.push({ name: 'groups-detail', params: { id } });
    },
    async remove (group) {
      if (isSuperAdmin(group)) {
        this.$toast.show({ type: 'error', text: 'Não pode remover grupo que tenha permissão de super admin' });
        return;
      }

      const accepted = await this.$dialog.show({
        title: 'Deletar grupo',
        message: `Realmente deseja deletar o grupo "${ group.name }"?`,
      });

      if (!accepted) return;

      await this.$s.group.remove(group.id);

      const index = this.groups.findIndex(p => p.id === group.id);
      this.groups.splice(index, 1);
    },
  },
};
</script>
